import React, { useEffect, useRef, useState } from "react";
import Isotope from "isotope-layout";
import ReactGA from "react-ga4";
import ProjectDetailsModal from "./ProjectDetailsModal";
const Portfolio = () => {
  // init one ref to store the future isotope object
  const isotope = useRef();
  // store the filter keyword in a state
  const [filterKey, setFilterKey] = useState("*");
  const [imagesLoaded, setimagesLoaded] = useState(0);
  const [selectedProjectDetails, setSelectedProjectDetails] = useState();
  const [isOpen, setIsOpen] = useState(false);

  const htmlElement = document.getElementsByTagName("html")[0];
  const isRtl = htmlElement.getAttribute("dir") === "rtl";

  const filters = {
    DETAILED: "Websites",
    RESTAPI: "REST APIs",
    WEBAPPS: "Web Apps",
  };

  const types = {
    IMAGE: "image",
    VIDEO: "video",
    DOCUMENT: "document",
    RESTAPI: "restapi",
  };

  const projectsData = [
    {
      title: "Short Stays Intl Website",
      type: types.DOCUMENT,
      document: {
        projectInfo:
          "Short Stays International is a boutique company specializing in Short-Term & Mid-Term Rental Management in the Greater Toronto Areas, Niagara Falls, Crystal Beach & Collingwood. The goal was to create an attractive and responsive but simple UI that would drive real estate owners and potential guests alike to reach out to Short Stays Intl to learn more about how the business could serve their needs.",
        client: "Jonathan Lim & Mai Nguyen",
        technologies: "ReactJS, JSX, CSS3, NodeJS, ExpressJS, MongoDB",
        industry: "Real Estate",
        date: "Mar 7, 2023",
        url: {
          name: "www.shortstaysintl.com",
          link: "https://shortstaysintl.com/",
        },

        sliderImages: [
          "images/projects/ssi_tncPg.jpg",
          "images/projects/ssi_faqPg.jpg",
        ],
        status: "Phase I Live. Phase II, custom content management in progress",
      },

      thumbImage: "images/projects/ssi_homePg.jpg",

      categories: [filters.DETAILED],
    },
    {
      title: "Toronto RC Heli Club Website",
      type: types.DOCUMENT,
      document: {
        projectInfo:
          "The Toronto RC Heli Club is a group of passionate RC helicopter enthusiasts who gather in a safe environment to fly their incredible rotory aircraft. The goal of this project was to build an exciting website that communicated the members' enjoyment of the hobby and attract others with an interest in the hobbly to join the club.",
        client: "Toronto RC Heli Club",
        technologies: "ReactJS, JSX, HTML5, CSS3/SASS",
        industry: "Club/Organization",
        date: "Feb 2, 2023",
        url: {
          name: "Staging",
          link: "https://torontorcheliclub.com/staging/",
        },

        sliderImages: [
          "images/projects/trchc_aboutPg.jpg",
          "images/projects/trchc_eventsPg.jpg",
          "images/projects/trchc_contactPg.jpg",
        ],
        status: "In staging for client review. Content from client pending",
      },

      thumbImage: "images/projects/trchc_homePg.jpg",

      categories: [filters.DETAILED],
    },
    {
      title: "Short Stays Intl Backend REST API",
      type: types.DOCUMENT,
      document: {
        projectInfo:
          "The goal of this implementation is to create an centralized database that stores all the content of the Short Stays Intl website and create a backend that properly handled form requests from the site as the client desired. The database and form handling features are accessed via API. Currently this REST API only serves database content for the site but in a phase II will have full CRUD operations to allow the client to manage their own site content via a secure, custom content management UI.",
        client: "Jonathan Lim & Mai Nguyen",
        technologies: "NodeJS, ExpressJS, MongoDB/Mongoose",
        industry: "Real Estate",
        date: "Mar 7, 2023",
        url: {
          name: "shortstaysintl-backend-RestAPI",
          link: "https://github.com/ajtibayan/shortstaysintl-backend-RestAPI",
        },
        status: "Phase I Live. Phase II, custom content management in progress",
      },

      thumbImage: "images/projects/ssi_restAPI.jpg",

      categories: [filters.RESTAPI],
    },
    // {
    //   title: "Mockups Design 1",
    //   type: types.IMAGE,

    //   thumbImage: "images/projects/project-2.jpg",

    //   categories: [filters.MOCKUPS],
    // },
    // {
    //   title: "YouTube Video",
    //   type: types.VIDEO,
    //   video: {
    //     vimeo: false,
    //     id: "PMNnEEEacCg",
    //   },
    //   thumbImage: "images/projects/project-3.jpg",

    //   categories: [filters.YOUTUBE],
    // },
    // {
    //   title: "Vimeo Video",
    //   type: types.VIDEO,
    //   video: {
    //     vimeo: true,
    //     id: "259411563",
    //   },

    //   thumbImage: "images/projects/project-4.jpg",
    //   categories: [filters.VIMEO],
    // },
    // {
    //   title: "Detailed Project 2",
    //   type: types.DOCUMENT,
    //   document: {
    //     projectInfo:
    //       "Quidam lisque persius interesset his et, in quot quidam persequeris vim, ad mea essent possim iriure. Lisque persius interesset his et, in quot quidam persequeris vim, ad mea essent possim iriure.",
    //     client: "Ruby Clinton",
    //     technologies: "iOS, HTML5, CSS3, PHP, Java",
    //     industry: "Art & Design",
    //     date: "July 16, 2019",
    //     url: {
    //       name: "www.example.com",
    //       link: "https://www.example.com",
    //     },
    //     sliderImages: [
    //       "images/projects/project-1.jpg",
    //       "images/projects/project-2.jpg",
    //     ],
    //   },
    //   thumbImage: "images/projects/project-5.jpg",
    //   categories: [filters.DETAILED],
    // },
    // {
    //   title: "Mockups Design 2",
    //   type: types.IMAGE,

    //   thumbImage: "images/projects/project-6.jpg",

    //   categories: [filters.MOCKUPS],
    // },
  ];

  // initialize an Isotope object with configs
  useEffect(() => {
    isotope.current = new Isotope(".portfolio-filter", {
      itemSelector: ".filter-item",
      layoutMode: "masonry",
      originLeft: !isRtl,
    });

    // cleanup
    return () => {
      isotope.current.destroy();
    };
  }, []);

  // handling filter key change
  useEffect(() => {
    if (imagesLoaded) {
      filterKey === "*"
        ? isotope.current.arrange({ filter: `*` })
        : isotope.current.arrange({ filter: `.${filterKey}` });
    }
  }, [filterKey, imagesLoaded]);

  const handleFilterKeyChange = (key) => () => setFilterKey(key);

  const getKeyByValue = (value) => {
    return Object.keys(filters).find((key) => filters[key] === value);
  };

  const getFilterClasses = (categories) => {
    if (categories.length > 0) {
      let tempArray = [];
      categories.forEach((category, index) => {
        tempArray.push(getKeyByValue(category));
      });
      return tempArray.join(" ");
    }
  };

  const analyticsTracker = (gaLabel) => {
    ReactGA.event({
      category: "Portfolio Section",
      action: "click",
      label: gaLabel,
    });
  };

  return (
    <>
      <section id="portfolio" className={"section bg-light"}>
        <div className={"container"}>
          {/* Heading */}
          <p className="text-center mb-2 wow fadeInUp">
            <span className="bg-primary text-dark px-2">Portfolio</span>
          </p>
          <h2 className="text-10 fw-600 text-center mb-5 wow fadeInUp">
            Some of my most recent works
          </h2>
          {/* Heading end*/}
          {/* Filter Menu */}
          <ul
            className={
              "portfolio-menu nav nav-tabs fw-600 justify-content-start justify-content-md-center border-bottom-0 mb-5 wow fadeInUp"
            }
          >
            <li className="nav-item">
              <button
                className={"nav-link " + (filterKey === "*" ? "active" : "")}
                onClick={handleFilterKeyChange("*")}
              >
                All
              </button>
            </li>
            {Object.keys(filters).map((oneKey, i) => (
              <li className="nav-item" key={i}>
                <button
                  className={
                    "nav-link " + (filterKey === oneKey ? "active" : "")
                  }
                  onClick={handleFilterKeyChange(oneKey)}
                >
                  {filters[oneKey]}
                </button>
              </li>
            ))}
          </ul>
          {/* Filter Menu end */}
          <div className="portfolio wow fadeInUp">
            <div className="row portfolio-filter filter-container g-4">
              {projectsData.length > 0 &&
                projectsData.map((project, index) => (
                  <div
                    className={
                      "col-sm-6 col-lg-4 filter-item " +
                      getFilterClasses(project.categories)
                    }
                    key={index}
                  >
                    <div className="portfolio-box">
                      <div className="portfolio-img">
                        <img
                          onLoad={() => {
                            setimagesLoaded(imagesLoaded + 1);
                          }}
                          className="img-fluid d-block portfolio-image"
                          src={project.thumbImage}
                          alt=""
                        />
                        <div
                          className="portfolio-overlay"
                          onClick={(e) => {
                            setSelectedProjectDetails(projectsData[index]);
                            setIsOpen(true);
                            analyticsTracker(project.title);
                          }}
                        >
                          <button className="popup-ajax stretched-link border-0 p-0 ">
                            {" "}
                          </button>
                          <div className="portfolio-overlay-details">
                            <p className="text-primary text-8">
                              {project.type === types.DOCUMENT && (
                                <i className="fas fa-file-alt"></i>
                              )}
                              {project.type === types.IMAGE && (
                                <i className="fas fa-image"></i>
                              )}
                              {project.type === types.VIDEO && (
                                <i className="fas fa-video"></i>
                              )}
                            </p>
                            <h5 className="text-white text-5">
                              {project?.title}
                            </h5>
                            <span className="text-light">Category</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
            </div>
          </div>
        </div>
      </section>
      {/* Modal */}
      {isOpen && (
        <ProjectDetailsModal
          projectDetails={selectedProjectDetails}
          setIsOpen={setIsOpen}
        ></ProjectDetailsModal>
      )}
    </>
  );
};

export default Portfolio;
