import React from "react";
import ReactGA from "react-ga4";
import resumeFile from "../documents/AJTibayan_resume.pdf";

const Resume = () => {
  const educationDetails = [
    {
      yearRange: "2015 - 2017",
      title: "Master in Computer Engineering",
      place: "Harvard University",
      desc: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the dummy text.",
    },
    {
      yearRange: "2014 - 2015",
      title: "Bachelor in Computer Engineering",
      place: "University of California",
      desc: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the dummy text.",
    },
    {
      yearRange: "2013 - 2014",
      title: "International Science",
      place: "Harvard University",
      desc: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the dummy text.",
    },
  ];

  const experienceDetails = [
    {
      yearRange: "Nov 2022 - current",
      title: "Full Stack Developer",
      place: "Freelance",
      desc: [
        "Developing new user-facing features using React",
        "Building reusable components and front-end libraries",
        "Translating business needs into high quality UI interfaces",
        "Deploying REST APIs/CRUD operations built on Express, Node and MongoDB NoSQL databases",
        "Propagated and administering VPS Server on Linux/CentOS running Apache and Nginx to host solutions",
      ],
    },
    {
      yearRange: "2013 - 10/2017",
      title: "Tech Lead & Developer",
      place: "The Foundry by Monster",
      desc: [
        "Developed and contributed to 100+ successful websites and social media recruiting solutions",
        "Administered dedicated Linux/Ubuntu server running Apache for all client and internal hosting",
        "Clientside dev with HTML5, Bootstrap/CSS, JS/jQuery, WordPress",
        "Serverside dev with PHP, MySQL/SQL",
        "Data managed AJAX, XML/JSON, REST APIs, cURL",
      ],
    },
    {
      yearRange: "01/2011 - 10/2017",
      title: "Web Developer & Project Tech Lead",
      place: "Monster.ca",
      desc: [
        "Developed all Custom Media Solutions (ie. Career Sites, targetmail email templates, etc.)",
        "Oversaw all technical aspects of projects outsourced to partners and 3rd party vendors",
        "Technical Lead and consultant on all Custom Media Projects",
        "Managed all domains, sub-domains, servers and databases",
        "Dev Env: LAMP, PHP, MySQL, JS/jQuery, CMS (Joomla/WordPress/Drupal), AJAX, SML, JSON, HTML5, CSS3, Social Media Implementations",
      ],
    },
    {
      yearRange: "2006 - 2011",
      title: "Full Stack Developer",
      place: "All In Designs Inc.",
      desc: [
        "Handled all aspects of projects from inception to deployment",
        "Wireframing and Design of solutions",
        "Frontend and Backend development of all websites",
        "Designed and architecture of all databases",
        "Configured, updated, maintained LAMP server",
        "Project management of all client communications and project deliverables",
        "Oversaw all ongoing website maintenance",
      ],
    },
  ];

  const skills = [
    {
      name: "Javascript",
      percent: 95,
    },
    {
      name: "HTML/CSS",
      percent: 95,
    },
    {
      name: "React JS",
      percent: 85,
    },
    {
      name: "Node JS",
      percent: 70,
    },
    {
      name: "Express JS",
      percent: 75,
    },
    {
      name: "MongoDB",
      percent: 75,
    },
    {
      name: "PHP",
      percent: 85,
    },
    {
      name: "jQuery",
      percent: 80,
    },
    {
      name: "SASS",
      percent: 60,
    },
    {
      name: "TypeScript",
      percent: 60,
    },
    {
      name: "Linux Ubuntu/CentOS",
      percent: 80,
    },
    {
      name: "Apache",
      percent: 75,
    },
  ];

  const analyticsTracker = (e) => {
    ReactGA.event({
      category: "Resume Section",
      action: "click",
      label: e.target.id,
    });
  };

  return (
    <section id="resume" className="section">
      <div className="container">
        {/* Heading */}
        <p className=" text-center mb-2 wow fadeInUp">
          <span className="bg-primary text-dark px-2">Resume</span>
        </p>
        <h2 className="text-10 fw-600 text-center mb-5 wow fadeInUp">
          A summary of My Resume
        </h2>
        {/* Heading end*/}
        <div className="row g-5 mt-5">
          {/* My Education */}
          <div className="col-lg-12 mt-2 wow fadeInUp">
            <h2 className="text-7 fw-600 mb-4 pb-2">My Experience</h2>
            <p>
              <i>
                "Unless you try to do something beyond what you have already
                mastered, you will never grow."
              </i>{" "}
              - Ronald E. Osborn
            </p>
          </div>
          <div className="row mt-0 wow fadeInUp">
            {/* <h2 className="text-7 fw-600 mb-4 pb-2">My Education</h2> */}
            {/* <div className="border-start border-2 border-primary ps-3"> */}
            {experienceDetails.length > 0 &&
              experienceDetails.map(
                ({ title, place, yearRange, desc }, index) =>
                  index < 2 && (
                    <div className="col-lg-6 mt-0 wow fadeInUp">
                      <div className="border-start border-2 border-primary ps-3">
                        <div key={index}>
                          <h3 className="text-5">{title}</h3>
                          <p className="mb-2">
                            {place} / {yearRange}
                          </p>
                          <ul>
                            {desc.map((bullet, index) => {
                              return <li key={index}>{bullet}</li>;
                            })}
                          </ul>
                          {/* <p className="text-muted">{desc}</p> */}
                          <hr className="my-4" />
                        </div>
                      </div>
                    </div>
                  )
              )}
            {/* </div> */}
          </div>
          {/* My Experience */}
          <div className="row mt-0 wow fadeInUp">
            {/* <h2 className="text-7 fw-600 mb-4 pb-2">My Experience</h2> */}
            {/* <div className="border-start border-2 border-primary ps-3"> */}
            {experienceDetails.length > 0 &&
              experienceDetails.map(
                ({ title, place, yearRange, desc }, index) =>
                  index > 1 &&
                  index < 4 && (
                    <div
                      className="col-lg-6 mt-0 wow fadeInUp"
                      data-wow-delay="0.2s"
                    >
                      <div className="border-start border-2 border-primary ps-3">
                        <div key={index}>
                          <h3 className="text-5">{title}</h3>
                          <p className="mb-2">
                            {place} / {yearRange}
                          </p>
                          <ul>
                            {desc.map((bullet, index) => {
                              return <li key={index}>{bullet}</li>;
                            })}
                          </ul>
                          {/* <p className="text-muted">{value.desc}</p> */}
                          <hr className="my-4" />
                        </div>
                      </div>
                    </div>
                  )
              )}
            {/* </div> */}
          </div>
        </div>
        {/* My Skills */}
        <h2 className="text-7 fw-600 mb-4 pb-2 mt-5 wow fadeInUp">My Skills</h2>
        <p>
          <i>
            "The more I live, the more I learn. The more I learn, the more I
            realize, the less I know."
          </i>{" "}
          - Michel Legrand
        </p>
        <div className="row gx-5">
          {skills.length > 0 &&
            skills.map((skill, index) => (
              <div className="col-md-6 wow fadeInUp" key={index}>
                <p className="fw-500 text-start mb-2">
                  {skill.name}{" "}
                  <span className="float-end">{skill.percent}%</span>
                </p>
                <div className="progress progress-sm mb-4">
                  <div
                    className="progress-bar"
                    role="progressbar"
                    style={{ width: skill.percent + "%" }}
                    aria-valuenow={skill.percent}
                    aria-valuemin={0}
                    aria-valuemax={100}
                  />
                </div>
              </div>
            ))}
        </div>
        <p className="text-center mt-5 wow fadeInUp">
          <a
            className="btn btn-outline-dark shadow-none rounded-0"
            id="downloadResume"
            href={resumeFile}
            onClick={(e) => analyticsTracker(e)}
            download
          >
            Download CV
          </a>
        </p>
      </div>
    </section>
  );
};

export default Resume;
