import React from "react";
import { Link } from "react-scroll";
import { Parallax } from "react-parallax";
import Typewriter from "typewriter-effect";
import ReactGA from "react-ga4";
import { scrollDuration } from "../../../config/commonConfig";

import "react-toastify/dist/ReactToastify.css";
import { Tooltip } from "../../Tooltip";

const FullScreenImgBgIntro = () => {
  const analyticsTracker = (e) => {
    ReactGA.event({
      category: "Intro Section",
      action: "click",
      label: e.target.id,
    });
  };

  return (
    <section id="home">
      <div className="hero-wrap">
        <div className="hero-mask opacity-8 bg-dark" />
        <Parallax
          bgImage="images/intro-bg.jpg"
          bgImageAlt="Intro"
          strength={200}
        >
          <div className="hero-content d-flex fullscreen py-5">
            <div className="container my-auto py-4">
              <div className="row">
                <div className="col-lg-12 text-center order-1 order-lg-0">
                  <h1 className="text-8 text-white fw-400 mb-0 text-uppercase">
                    Hi, I'm a Full Stack
                  </h1>
                  <h2 className="text-23 text-white fw-600 text-uppercase mb-0 ms-n1">
                    <Typewriter
                      options={{
                        strings: ["Developer", "Engineer", "MERN", "LAMP"],
                        autoStart: true,
                        loop: true,
                      }}
                    />{" "}
                  </h2>
                  <p className="text-5 text-light">based in Whitby, ON.</p>
                  <Link
                    className="btn btn-primary fw-600 rounded-0 smooth-scroll mt-3"
                    id="introPorfolio"
                    smooth="easeInOutQuint"
                    duration={scrollDuration}
                    style={{ cursor: "pointer" }}
                    to="portfolio"
                    onClick={(e) => analyticsTracker(e)}
                  >
                    Portfolio
                  </Link>
                  <ul className="social-icons social-icons-light social-icons-lg justify-content-center mt-5">
                    <li className="social-icons-twitter">
                      <Tooltip text="Twitter" placement="top">
                        <a
                          href="https://twitter.com/AJTibayan"
                          target="_blank"
                          rel="noopener noreferrer"
                          id="introTwitter"
                          onClick={(e) => analyticsTracker(e)}
                        >
                          <i className="fab fa-twitter" />
                        </a>
                      </Tooltip>
                    </li>
                    <li className="social-icons-facebook">
                      <Tooltip text="Facebook" placement="top">
                        <a
                          href="https://www.facebook.com/AJTibayan21"
                          target="_blank"
                          rel="noopener noreferrer"
                          id="introFacebook"
                          onClick={(e) => analyticsTracker(e)}
                        >
                          <i className="fab fa-facebook" />
                        </a>
                      </Tooltip>
                    </li>
                    <li className="social-icons-instagram">
                      <Tooltip text="Instagram" placement="top">
                        <a
                          href="https://www.instagram.com/tibayana/"
                          target="_blank"
                          rel="noopener noreferrer"
                          id="introInstagram"
                          onClick={(e) => analyticsTracker(e)}
                        >
                          <i className="fab fa-instagram" />
                        </a>
                      </Tooltip>
                    </li>
                    <li className="social-icons-linkedin">
                      <Tooltip text="LinkedIn" placement="top">
                        <a
                          href="https://www.linkedin.com/in/ajtibayan/"
                          target="_blank"
                          rel="noopener noreferrer"
                          id="introLinkedIn"
                          onClick={(e) => analyticsTracker(e)}
                        >
                          <i className="fab fa-linkedin-in" />
                        </a>
                      </Tooltip>
                    </li>
                    <li className="social-icons-github">
                      <Tooltip text="Github" placement="top">
                        <a
                          href="https://github.com/ajtibayan"
                          target="_blank"
                          rel="noopener noreferrer"
                          id="introGithub"
                          onClick={(e) => analyticsTracker(e)}
                        >
                          <i className="fab fa-github" />
                        </a>
                      </Tooltip>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <Link
              className="scroll-down-arrow text-light smooth-scroll"
              id="introArrowDown"
              smooth="easeInOutQuint"
              duration={scrollDuration}
              style={{ cursor: "pointer" }}
              to="about"
              onClick={(e) => analyticsTracker(e)}
            >
              <span className="animated">
                <i className="fas fa-arrow-down" />
              </span>
            </Link>
          </div>
        </Parallax>
      </div>
    </section>
  );
};

export default FullScreenImgBgIntro;
