import React from "react";
import ReactGA from "react-ga4";

const Footer = () => {
  const analyticsTracker = (e) => {
    ReactGA.event({
      category: "Footer Section",
      action: "click",
      label: e.target.id,
    });
  };

  return (
    <footer id="footer" className="section bg-dark text-white">
      <div className="container">
        <div className="row">
          <div className="col-lg-6 text-center text-lg-start wow fadeInUp">
            <p className="mb-2 mb-lg-0">
              Copyright © 2023{" "}
              <a
                className="fw-600"
                id="footerHome"
                href="/"
                onClick={(e) => analyticsTracker(e)}
              >
                A.J. Tibayan
              </a>
              . All Rights Reserved.
            </p>
          </div>
          {/* <div className="col-lg-6 wow fadeInUp">
            <p className="mb-0 text-center text-lg-end">
              Developed by{" "}
              <a className="fw-600" href="/">
                A.J. Tibayan
              </a>
            </p>
          </div> */}
        </div>
      </div>
    </footer>
  );
};

export default Footer;
