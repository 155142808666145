import React from "react";
import ReactGA from "react-ga4";
const AboutUs = () => {
  const analyticsTracker = (e) => {
    ReactGA.event({
      category: "About Section",
      action: "click",
      label: e.target.id,
    });
  };
  return (
    <section id="about" className="section">
      <div className="container">
        {/* Heading */}
        <p className="text-center mb-2 wow fadeInUp">
          <span className="bg-primary text-dark px-2">About Me</span>
        </p>
        <h2 className="text-10 fw-600 text-center mb-5 wow fadeInUp">
          Know Me More
        </h2>
        {/* Heading end*/}
        <div className="row">
          <div className="col-lg-8 text-center text-lg-start wow fadeInUp">
            <h2 className="text-8 fw-400 mb-3">
              Hi, I'm{" "}
              <span className="fw-700 border-bottom border-3 border-primary">
                A.J. Tibayan
              </span>
            </h2>
            <p className="text-5">
              Full Stack Developer with 10+ years of experience working on a
              LAMP stack and more recently migrating to a MERN stack. Developed
              20+ websites for clients with 9C Marketing, worked on 100+ online
              & Social Media solutions with the media team at Monster.ca/The
              Foundry by Monster. I am excited to work with a company to play a
              key roll in helping them accomplish their online goals while
              continuing to evolve my skill sets to stay on the cutting edge of
              online and mobile application development.
            </p>
          </div>
          <div
            className="col-lg-4 mt-4 mt-lg-0 wow fadeInUp"
            data-wow-delay="0.2s"
          >
            <div className="featured-box style-4">
              <div className="featured-box-icon text-25 fw-500 bg-primary rounded-circle">
                <span className="wow heartBeat" data-wow-delay="1.3s">
                  10+
                </span>
              </div>
              <h3 className="text-7 wow rubberBand" data-wow-delay="2s">
                Years of <span className="fw-700">Experiance</span>
              </h3>
            </div>
          </div>
        </div>
        <div className="row gy-3 mt-4">
          <div className="col-6 col-lg-3 wow fadeInUp">
            <p className="text-muted fw-500 mb-0">Name:</p>
            <p className="text-4 text-dark fw-600 mb-0">A.J. Tibayan</p>
          </div>
          <div className="col-6 col-lg-3 wow fadeInUp" data-wow-delay="0.2s">
            <p className="text-muted fw-500 mb-0">Email:</p>
            <p className="text-4 fw-600 mb-0">
              <a
                className="link-dark about-link"
                id="aboutEmail"
                href="mailto:aj.tibayan@ajtibayan.com"
                onClick={(e) => analyticsTracker(e)}
              >
                aj.tibayan@ajtibayan.com
              </a>
            </p>
          </div>
          <div className="col-6 col-lg-3 wow fadeInUp" data-wow-delay="0.3s">
            <p className="text-muted fw-500 mb-0">Phone:</p>
            <p className="text-4 text-dark fw-600 mb-0">
              <a
                className="about-link"
                href="tel:416-710-7750"
                id="aboutPHone"
                onClick={(e) => analyticsTracker(e)}
              >
                +1 (416) 710-7750
              </a>
            </p>
          </div>
          <div className="col-6 col-lg-3 wow fadeInUp" data-wow-delay="0.4s">
            <p className="text-muted fw-500 mb-0">From:</p>
            <p className="text-4 text-dark fw-600 mb-0">Whitby, ON, Canada.</p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default AboutUs;
